<template>
  <div>
    <div
      v-for="(groupe, index) in value"
      :key="index"
      class="my-4"
    >
      <h2>
        Groupe {{ index + 1 }}
        <el-button
          type="text"
          icon="el-icon-caret-top"
          :disabled="index === 0 || disabled"
          @click="exchangeGroupes(index, index-1)"
        />
        <el-button
          type="text"
          icon="el-icon-caret-bottom"
          :disabled="index === (value.length - 1) || disabled"
          @click="exchangeGroupes(index, index+1)"
        />
        <el-button
          type="text"
          icon="el-icon-delete"
          :disabled="disabled"
          @click="deleteGroupe(groupe.id, index)"
        />
      </h2>
      <el-input v-model="groupe.title" />
      <el-input
        v-model="groupe.description"
        type="textarea"
        :disabled="disabled"
      />
      <el-table
        class="grid"
        :data="groupe.phases"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="flex flew-row">
              <div class="flex-grow">
                <el-col :span="8">
                  <el-row>
                    Description :
                    <el-input
                      v-model="props.row.description"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4}"
                      placeholder="Description de la phase"
                      :disabled="disabled"
                    />
                  </el-row>
                  <el-row>
                    Méthodologie :
                    <el-input
                      v-model="props.row.methodologie"
                      type="textarea"
                      :autosize="{ minRows: 3, maxRows: 4}"
                      placeholder="Méthodologie de la phase"
                      :disabled="disabled"
                    />
                  </el-row>
                </el-col>
                <el-col
                  :span="15"
                  :offset="1"
                >
                  <el-table
                    :data="props.row.uss"
                  >
                    <el-table-column
                      label="Liste des US"
                      align="center"
                    >
                      <template slot-scope="usData">
                        <el-input
                          v-model="usData.row.name"
                          type="text"
                          placeholder="Nom de l'US"
                          :disabled="disabled"
                        />
                      </template>
                    </el-table-column>
                    <el-table-column
                      label=""
                      align="center"
                      width="80px"
                      fixed="right"
                    >
                      <template slot-scope="usData">
                        <el-button
                          icon="el-icon-minus"
                          type="danger"
                          plain
                          :disabled="disabled"
                          @click="deleteUS(usData.row, props.row)"
                        />
                      </template>
                    </el-table-column>
                  </el-table>
                  <el-button
                    type="text"
                    icon="el-icon-plus"
                    :disabled="disabled"
                    @click="addUS(props.row)"
                  >
                    Ajouter une US
                  </el-button>
                </el-col>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Titre"
        >
          <template slot-scope="props">
            <el-input
              v-model="props.row.title"
              :disabled="disabled"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="110"
          label="Nb JEH"
        >
          <template slot-scope="props">
            <el-input-number
              v-model="props.row.nbJEH"
              controls-position="right"
              :min="1"
              :max="99"
              style="width: 90%"
              size="small"
              :disabled="disabled"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="Prix JEH"
        >
          <template slot-scope="props">
            <el-input
              v-model="props.row.priceJEH"
              :disabled="disabled"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="!small"
          width="150"
          label="Date de début"
        >
          <template slot-scope="props">
            <el-date-picker
              v-model="props.row.beginDate"
              type="date"
              placeholder="Choisissez un jour"
              width="200"
              size="mini"
              format="dd-MM-yyyy"
              value-format="yyyy-MM-dd"
              :disabled="disabled"
            />
          </template>
        </el-table-column>
        <el-table-column
          v-if="!small"
          width="110"
          label="Durée (jours)"
        >
          <template slot-scope="props">
            <el-input-number
              v-model="props.row.period"
              controls-position="right"
              :min="1"
              :max="99"
              style="width: 90%"
              size="small"
              :disabled="disabled"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="50"
        >
          <template slot-scope="props">
            <el-button
              type="text"
              icon="el-icon-caret-top"
              :disabled="disabled"
              @click="exchangeItems(index, props.$index, props.$index - 1)"
            /><br>
            <el-button
              type="text"
              icon="el-icon-caret-bottom"
              :disabled="disabled"
              @click="exchangeItems(index, props.$index, props.$index + 1)"
            />
          </template>
        </el-table-column>
        <el-table-column
          width="50"
        >
          <template slot-scope="props">
            <el-button
              type="text"
              :disabled="disabled"
              icon="el-icon-delete"
              @click="deletePhase(props.row, groupe)"
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="flex justify-center">
        <el-button
          type="text"
          icon="el-icon-plus"
          :disabled="disabled"
          @click="addPhase(groupe)"
        >
          Ajouter une phase
        </el-button>
      </div>
    </div>
    <div class="flex justify-center">
      <el-button
        :disabled="disabled"
        @click="addGroupe"
      >
        Ajouter un groupe
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "PhasesForm",
  props: {
    value: { type: Array, required: true },
    small: { type: Boolean, required: true },
    disabled: { type: Boolean, default: false }
  },
  methods: {
    addGroupe () {
      this.$emit("add-groupe")
    },
    addPhase (groupe) {
      this.$emit("add-phase", groupe)
    },
    addUS (phase) {
      this.$emit("add-us", phase)
    },
    exchangeGroupes (index1, index2) {
      if (index1 < 0 || index1 >= this.value.length || index2 < 0 || index2 >= this.value.length) {
        return
      }
      [
        this.value[index1].index,
        this.value[index2].index
      ] = [
        this.value[index2].index,
        this.value[index1].index
      ]
      this.value = this.value.sort((a, b) => a.index - b.index)
    },
    exchangeItems (groupe_index, index1, index2) {
      if (index1 < 0 || index1 >= this.value[groupe_index].phases.length || index2 < 0 || index2 >= this.value[groupe_index].phases.length) {
        return
      }
      [
        this.value[groupe_index].phases[index1].index,
        this.value[groupe_index].phases[index2].index
      ] = [
        this.value[groupe_index].phases[index2].index,
        this.value[groupe_index].phases[index1].index
      ]
      this.value[groupe_index].phases = this.value[groupe_index].phases.sort((a, b) => a.index - b.index)
    },
    deleteGroupe (groupeId, groupeIndex) {
      this.$emit("delete-groupe", {groupeId, groupeIndex})
    },
    deletePhase (phase, groupe) {
      this.$emit("delete-phase", {phase, groupe})
    },
    deleteUS (us, phase) {
      this.$emit("delete-us", {us, phase})
    }
  }
}
</script>
